/**
©2018 EdgeVerve Systems Limited (a fully owned Infosys subsidiary),
Bangalore, India. All Rights Reserved.
**/


window.sbBehaviours = window.sbBehaviours || {};
sbBehaviours.currencyFormatorBehaviour = {


    properties: {
        symbol: {
            type: String,
            notify: true
                // symbol to display
        },
        Precision: {
            type: Number,
            notify: true,
            value: 2
            // precision to display while displaying amount
        },

        locale: {
            type: String,
            notify: true
                // country based string for formatting the amount
        }


    },
    // function that formats amount into lakh format or million format
    formatNumber: function(num) {
        var locale;
        var amountString;
        if (this.amountFormat)
            locale = this.amountFormat
        else
            locale = "en-US";
        //this.Precision is set based on the precisiondigit value set against corresponding currency.
        amountString = (Number(num).toLocaleString(locale, {
            minimumFractionDigits: this.Precision,
            maximumFractionDigits: this.Precision
        }));

        if (this.separator === "comma") {
            var separatedAmt = amountString.split(".");
            separatedAmt[0] = amountString[0].replace(/,/g, ".");
            amountString = separatedAmt[0] + "," + separatedAmt[1];
        }
        return amountString;
    },
    // below function is substitute for currency.js
    // function that will check currencyConfig object for its currency to get symbol and locale
    _symbolizeCurrency: function(inputCode) {
        var arrayAmount = new Array();
        if (inputCode != undefined) {
            arrayAmount = inputCode.toString().split(' ');
            //arrayAmount[0] holds the currency value
            //arrayAmount[1] holds the amount
            if (config.SpeedBoat.App.CurrencySymbolize.Symbolize === "Enabled") {
                if (currencyConfig != undefined || currencyConfig != null || currencyConfig != "") {
                    if (currencyConfig[arrayAmount[0]] === undefined) {
                        this.symbol = arrayAmount[0];
                        this.locale = undefined;
                    } else {
                        this.symbol = currencyConfig[arrayAmount[0]].symbol;
                        this.locale = currencyConfig[arrayAmount[0]].locale;
                    }
                } else {
                    this.symbol = arrayAmount[0];
                    this.locale = undefined;
                }
            } else {
                this.symbol = arrayAmount[0];
                this.locale = undefined;
            }

            if (arrayAmount.length === 2) {
                //setting this.Precision based on the precisiondigit value set against corresponding currencies in currency-config.json
                this.Precision = currencyConfig[arrayAmount[0]] && currencyConfig[arrayAmount[0]].precisiondigit != undefined ? currencyConfig[arrayAmount[0]].precisiondigit : this.Precision;
                if (config.SpeedBoat.App.CurrencySymbolize.ShowSymbols === "Enabled") {
                    return this.symbol + " " + this.formatNumber(arrayAmount[1]);
                } else return this.formatNumber(arrayAmount[1]);
            } else {
                if (!isNaN(inputCode)) {
                    return this.formatNumber(arrayAmount[0]);
                } else {
                    if (config.SpeedBoat.App.CurrencySymbolize.ShowSymbols === "Enabled") return this.symbol;
                    else return "";
                }
            }
        }
    },
    // function to format the amount along with precision to be shown in listing screens
    _formatCurrencywithPrecision: function (currency, amount) {
        if (currency != undefined && amount != undefined) {
            //setting this.Precision based on the precisiondigit value set against corresponding currencies in currency-config.json
            this.Precision = currencyConfig[currency].precisiondigit != undefined ? currencyConfig[currency].precisiondigit : this.Precision;
            if (this.Precision != undefined)
                return this.formatNumber(amount);
            else {
                return amount;
            }
        }
    }
};