/**
©2018 EdgeVerve Systems Limited (a fully owned Infosys subsidiary),
Bangalore, India. All Rights Reserved.
**/
import './currency-formator-behaviour.js';

window.corpBehaviours = window.corpBehaviours || {};
corpBehaviours.appCommonBehaviourChild = {
    properties: {
        dateFormat: {
            type: String
        },
        userId: {
            type: String
        },
        corpId: {
            type: String
        },
        //to support multi lnaguages
        locale: String
    },
    ready: function () {
        this.dateFormat = clientStorage.get(storageType.session, 'dateFormat');
        this.locale = clientStorage.get(storageType.session, 'lang');
        var tempAmountFormat = clientStorage.get(storageType.session, 'amountFormat');
        if (tempAmountFormat == "Lakh Format") {
            this.amountFormat = "en-IN";
        } else {
            this.amountFormat = "en-US";
        }
        this.userId = clientStorage.get(storageType.session, 'userId');
        this.corpId = clientStorage.get(storageType.session, 'corpId');
    },
    formatCurrency: function (amt, sym) {
        if (!_.isNil(amt) && sym) {
            return this._symbolizeCurrency(sym + ' ' + amt);
        }
        return '';
    },
    formatAmountWithoutCurrency: function (amt) {
        if (!_.isNil(amt)) {
            this.homeCurrency = clientStorage.get(storageType.session, 'homeCurrency');
            return this._symbolizeCurrency(this.homeCurrency + ' ' + amt);
        }
        return '';
    },
    formatAddress: function (obj) {
        var str = '';
        if (obj) {
            str += !_.get(obj, 'address') ? '' : _.get(obj, 'address');
            str += (str ? ", " : "") + (!_.get(obj, 'addressLine1') ? '' : _.get(obj, 'addressLine1'));
            str += (str ? ", " : "") + (!_.get(obj, 'addressLine2') ? '' : _.get(obj, 'addressLine2'));
            str += (str ? ", " : "") + (!_.get(obj, 'city') ? '' : _.get(obj, 'city'));
            str += (str ? ", " : "") + (_.isString(obj.state) ? _.get(obj, 'state') :
                (!_.get(obj, 'state.stateDescription') ? '' : _.get(obj, 'state.stateDescription')));
            str += (str ? ", " : "") + (!_.get(obj, 'zipCode') ? '' : _.get(obj, 'zipCode'));
            str += (str ? ", " : "") + (_.isString(obj.country) ? _.get(obj, 'country') :
                (!_.get(obj, 'country.codeDescription') ? '' : _.get(obj, 'country.codeDescription')));
        }
        return str;
    },
    maskedAccount: function (accountId, codeType) {
        return accountId ? maskAccount.accountFormat(accountId, codeType) : '';
    },
    formatDate: function (date) {
        var res = moment.utc(date);
        res = moment(res.toDate()).format(clientStorage.get(storageType.session, 'dateFormat'));
        return res;
    },
    displayLiteral: function (lit) {
        return getLabel(lit);
    },
    checkEquality: function (value1, value2) {
        if (value1 == value2)
            return true;
        else
            return false;
    },
    logicalAnd: function (value1, value2) {
        if (value1 && value2)
            return true;
        return false;
    },
    //added to handle timestamp issue for dates displayed in UI components as a part of 326179 fix
    formatTemplateDate: function (date) {
        var formatedDate = setBusinessToDateString(date);
        if (!this.dateFormat)
            this.dateFormat = clientStorage.get(storageType.session, 'dateFormat');
        return OEUtils.DateUtils.format(formatedDate, this.dateFormat);
    },
    formatYesNoValues: function (value) {
        if (value == "Y" || value == "N") {
            if (value == "Y") {
                return getLabel('b_yestxt');
            }
            else return getLabel('b_notxt');
        }
    }
};
corpBehaviours.appCommonBehaviour = [corpBehaviours.appCommonBehaviourChild, sbBehaviours.currencyFormatorBehaviour];