/* ©2018 EdgeVerve Systems Limited (a fully owned Infosys subsidiary),
Bangalore, India. All Rights Reserved.
 */
import {
	html,
	PolymerElement
} from '@polymer/polymer/polymer-element.js';
import './app-styles.js';

const AppAggridCustomStyles = document.createElement('dom-module');

AppAggridCustomStyles.innerHTML =
	`<template>               
<style include="app-styles">  
	.ag-theme-material .ag-cell{
		font-size: 15px;
		font-family: 'Nunito-Regular';
		align-items: center;
	}
	.ag-theme-material .ag-header-cell-label {
		display: flex;
		height: auto;
		align-items: center;
	}
	.ag-theme-material .ag-header-cell-label .ag-header-icon {
		align-items: center;
	}
	_:-ms-lang(x), .ag-theme-material .ag-icon{
		font-family: none;
	}
	 .ag-grid-ir-cell-custom-class{
		   color:var(--brand-color);
		   cursor:pointer;              
		 }
	   .ag-theme-material .ag-header{
		   font-size: 14px !important;
		   font-family: 'Nunito-Regular' !important;
	   }
	   
	   _:-ms-lang(x), .ag-theme-material .ag-selection-checkbox span {
			top: 0px;
		}
		.icon-center{
            display: flex !important;
            align-items: center !important;
            justify-content: center;
		}
		.header-cell-centered .ag-header-cell-label{
			justify-content: center;
		}
		.header-cell-left .ag-header-cell-label{
			justify-content: flex-start;
		}
		.header-cell-right .ag-header-cell-label{
			justify-content: flex-end;
		}
		.ag-theme-material .ag-tooltip {
			font-size: var(--tooltip-font-size);
			color: var(--secondary-dark-color);
			background-color: var(--primary-light-color);
			box-shadow: 0 2px 4px 0 var(--secondary-dark-color);
			padding: 8px;
			line-height: 1;
			border-radius: 2px;
			transition: opacity 1s;
			animation-delay: 0ms;
		}
		.ag-theme-material {
            font-size: inherit !important;
        }
        .padding-top-zero {
            padding-top: 0px !important;
        }
<style>
 
</template>`;

AppAggridCustomStyles.register('app-aggird-custom-styles');